import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import * as actions from "../state/actions/keymans"
import { KEYMAN_PER_PAGE } from "../constants/constants"
import ListLayout from "../components/common/list-layout"
import KeymanItem from "../components/common/keyman-item"
import { Location } from "@reach/router"
import { getQueryVariable } from "../utils/str"

const mapStateToProps = (state, ownProps) => {
  return {
    keymans: state.search.keymans.toIndexedSeq().toArray(),
    pageInfo_keyman: state.search.pageInfo_keyman,
    isLoadingKeymans: state.search.isLoadingKeymans,
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
    isInitialized: state.search.isInitialized,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymans: (data, overwrite) =>
      dispatch(actions.getKeymans(data, overwrite)),
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
  }
}

const styles = theme => ({})

class Search extends React.Component {
  componentDidMount() {
    this.props.getKeymans(this.getQuery(this.props), true)
    this.props.getKeymansTotal()
    this.props.getKeymansYesterday()
  }

  getQuery = (props, query = {}) => {
    return {
      paging: 1,
      per_page: KEYMAN_PER_PAGE,
      page: 1,
      ...getQueryVariable(props.location.search, ["keyword", "page"]),
      ...query,
    }
  }

  render() {
    const {
      keymans,
      isLoadingKeymans,
      pageInfo_keyman,
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      location,
      isInitialized,
      updateKeymans,
      isLoadingUpdateKeymans,
    } = this.props

    const keyword = getQueryVariable(location.search, "keyword")

    return (
      <ListLayout
        name={keyword === undefined ? '検索結果' : `「${keyword}」の検索結果`}
        title={keyword === undefined ? '検索結果' : `「${keyword}」の検索結果`}
        ItemComponent={KeymanItem}
        items={keymans}
        isLoading={isLoadingKeymans}
        perPage={KEYMAN_PER_PAGE}
        pageInfo={pageInfo_keyman}
        getItems={(data, overwrite) =>
          this.props.getKeymans(this.getQuery(this.props, data), overwrite)
        }
        isLoadingUpdateKeymans={isLoadingUpdateKeymans}
        updateKeymans={updateKeymans}
        keymanCountYstd={keymanCountYstd}
        keymansTotal={keymansTotal}
        corporationsTotal={corporationsTotal}
        reverseLayout={false}
        path="search"
        params={getQueryVariable(this.props.location.search, ["keyword"])}
        isInitialized={isInitialized}
      />
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <Search {...locationProps} {...props} />}
    </Location>
  ))
)
